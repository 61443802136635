import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Pages
import { About } from './About';
import { Shiurim } from './Shiurim';
import { ShiurList } from './ShiurList';
import { Shiur } from './Shiur';

const Routes = () => {
    return (
        <Switch>
            <Route component={About} path="/about" />
            <Route component={Shiur} path="/shiur/:shiurId/:episodeId" />
            <Route component={Shiurim} path="/shiurim/:shiurId" />
            <Route component={ShiurList} path="/shiurim" />
            <Route component={About} path="" />
        </Switch>
    );
};

export default Routes;
