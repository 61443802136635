import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { slugify } from '../../util';

// Material UI
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

class Shiur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            episode: { Title: "", audio: "", video: "" },
        };
    }
    componentDidMount() {
        const {
            match: {
                params: { shiurId, episodeId },
            },
        } = this.props;
        this.fetchEpisode(shiurId, episodeId)
    }

    fetchEpisode = (shiurId, episodeId) => {
        axios
            .get(`/Rabbi_Viener/${shiurId}/episodes.json`)
            .then(res => {
                console.log(res)
                const episodes = res.data
                console.log(episodes)
                const title = episodeId.replaceAll("_", " ")
                console.log(title)
                const episode = episodes.find(ep => slugify(ep.Title) === title)
                this.setState({ episode: episode });
            })
    }


    // TODO: Add link generator to list items (copy to clipboard)
    // TODO: Update Google analytics (need info from tzadok)
    // TODO: Better title display
    // TODO: add google analytics play events here
    // INFO: Maybe update to functional components?
    render() {
        const { episode } = this.state
        return (
            <Container maxWidth="md">
                <Typography variant="h2">{this.state.episode.Title}</Typography>
                {episode.video ?
                    <video controls
                        onPlay={() => {
                            ReactGA.event({
                                category: 'Play Shiur',
                                action: `Shiur played: ${episode.Title}`,
                            });
                        }}
                        src={episode.video} style={{ width: "100%", margin: "2em 0 0 0" }} />
                    :
                    <audio
                        onPlay={() => {
                            ReactGA.event({
                                category: 'Play Shiur',
                                action: `Shiur played: ${episode.Title}`,
                            });
                        }}
                        controls src={episode.audio} style={{ width: "100%", margin: "5em 0 0 0" }} />
                }
            </Container>
        );
    }
}

export default withRouter(Shiur);
