import React, { useEffect, useState, createContext } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js';

const fuseOptions = {
    caseSensitive: false,
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: ['title'],
};

const GSCContext = createContext();

const GSCProvider = ({ children }) => {
    const [state, setState] = useState({ series: [], index: false });
    useEffect(() => {
        axios.get('/Rabbi_Viener/topics.json').then(res => {
            setState({
                series: res.data,
                index: new Fuse(res.data, fuseOptions),
            });
        });
    }, []);
    return <GSCContext.Provider value={state}>{children}</GSCContext.Provider>;
};

export { GSCContext, GSCProvider };
