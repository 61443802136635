import React, { useState } from 'react';

// Material Ui
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const PaginationList = ({ items, numPerPage, renderFunction }) => {
    const [activePage, setActivePage] = useState(0);
    const numOfPages = Math.ceil(items.length / numPerPage);
    const getPageItems = items =>
        items.slice(
            activePage * numPerPage,
            activePage * numPerPage + numPerPage
        );
    const handleNext = () => {
        setActivePage(activePage + 1);
    };
    const handleBack = () => {
        setActivePage(activePage - 1);
    };
    return (
        <React.Fragment>
            <MobileStepper
                activeStep={activePage}
                backButton={
                    <Button
                        disabled={activePage === 0}
                        onClick={handleBack}
                        size="small"
                    >
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                }
                nextButton={
                    <Button
                        disabled={activePage === numOfPages - 1}
                        onClick={handleNext}
                        size="small"
                    >
                        Next
                        <KeyboardArrowRight />
                    </Button>
                }
                position="static"
                steps={numOfPages}
                variant="text"
            />
            <br />
            {renderFunction(getPageItems(items))}
        </React.Fragment>
    );
};

export default PaginationList;
