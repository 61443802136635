import React from 'react';
import { GSCProvider } from './GSC';
import Navigation from './components/Navigation';

import { Router } from 'react-router-dom';
import Routes from './components/Routes';
import Box from '@material-ui/core/Box';
import Footer from './components/Footer';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const trackingId = 'UA-33191551-1';
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = () => {
    return (
        <GSCProvider>
            <div className="app">
                <div className="content-wrap">
                    <Router history={history}>
                        <Navigation />
                        <Box mt={10}>
                            <Routes />
                        </Box>
                    </Router>
                </div>
                <Footer />
            </div>
        </GSCProvider>
    );
};

export default App;
