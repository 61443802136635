import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Typography align="center" variant="body2">
                    Questions or comments? Please, contact the{' '}
                    <a
                        href="mailto:webmaster.torahstream at gmail dot com"
                        rel="tooltip"
                        title="Click to email the webmaster, make sure to edit the e-mail address prior to sending."
                    >
                        webmaster
                    </a>{' '}
                    or{' '}
                    <a
                        href="mailto:yv at torahstream dot org"
                        rel="tooltip"
                        title="Click to email Rabbi Viener, make sure to edit the e-mail address prior to sending."
                    >
                        Rabbi Viener
                    </a>
                    .
                </Typography>
            </Container>
        </footer>
    );
};

export default Footer;
