import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import { Tabs } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { Link, withRouter } from 'react-router-dom';

const Navigation = ({ history }) => {
    const tabVal = ['/shiurim', '/about'].includes(history.location.pathname)
        ? history.location.pathname
        : history.location.pathname === '/'
        ? '/about'
        : false;

    return (
        <nav style={{ flexGrow: 1 }}>
            <AppBar>
                <Container style={{ display: 'flex' }}>
                    <Toolbar>
                        <Typography
                            component="span"
                            display="inline"
                            gutterBottom
                            noWrap
                            variant="h6"
                        >
                            TorahStream
                        </Typography>
                    </Toolbar>
                    <div style={{ display: 'inline-flex', marginLeft: 'auto' }}>
                        <Tabs value={tabVal}>
                            <Tab
                                component={Link}
                                label="Shiurim"
                                to="/shiurim"
                                value="/shiurim"
                            />
                            <Tab
                                component={Link}
                                label="About"
                                to="/about"
                                value="/about"
                            />
                        </Tabs>
                    </div>
                </Container>
            </AppBar>
        </nav>
    );
};

export default withRouter(Navigation);
