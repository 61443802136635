import React from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ReactGA from 'react-ga';

// mui icons
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import VideocamIcon from '@material-ui/icons/Videocam';

const SeriesHeader = ({ data }) => {
    return (
        <>
            <Box mb={2}>
                <Typography
                    gutterBottom
                    variant="h4"
                >{`${data.title} (${data.count})`}</Typography>
                <Button
                    color="primary"
                    component="a"
                    href={data.podcast}
                    onClick={() => {
                        ReactGA.event({
                            category: 'Clicked on Podcast',
                            action: `Podcast link: ${data.title}`,
                        });
                    }}
                    startIcon={<QueueMusicIcon />}
                    variant="contained"
                >
                    Audio Podcast
                </Button>{' '}
                {data.vodcast && (
                    <Button
                        color="secondary"
                        component="a"
                        href={data.vodcast}
                        onClick={() => {
                            ReactGA.event({
                                category: 'Clicked on Vodcast',
                                action: `Vodcast link: ${data.title}`,
                            });
                        }}
                        startIcon={<VideocamIcon />}
                        variant="contained"
                    >
                        Video Podcast
                    </Button>
                )}
            </Box>
        </>
    );
};

export default SeriesHeader;
