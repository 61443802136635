import React, { useState, useContext } from 'react';
import SeriesList from './components/SeriesList';
import { GSCContext } from '../../GSC';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const ShiurList = props => {
    const [state, setState] = useState({
        query: '',
    });
    const GSC = useContext(GSCContext);

    const getSeries = () => {
        if (GSC.index && state.query.length > 0) {
            const matches = GSC.index.search(state.query);
            return matches;
        } else {
            return GSC.series;
        }
    };

    return (
        <Container maxWidth="md">
            <Typography gutterBottom variant="h4">
                Choose A Topic
            </Typography>
            <TextField
                fullWidth
                onChange={e => setState({ ...state, query: e.target.value })}
                placeholder="Search..."
                type="text"
                value={state.query}
                variant="outlined"
            />
            <br />
            <br />
            {GSC.series.length > 0 ? (
                <SeriesList series={getSeries()} />
            ) : (
                <Box mt={20}>
                    <Typography align="center" component="div">
                        <CircularProgress
                            color="primary"
                            size={100}
                            thickness={2}
                        />
                    </Typography>
                </Box>
            )}
        </Container>
    );
};

export default ShiurList;
