import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';

// mui icons
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import VideocamIcon from '@material-ui/icons/Videocam';

const SeriesList = ({ series = [] }) => {
    return (
        <List>
            {series.map(s => (
                <ListItem
                    component={Link}
                    divider
                    key={s.id}
                    style={{
                        textTransform: 'none',
                    }}
                    to={`/shiurim/${s.id}`}
                >
                    <ListItemIcon>
                        <React.Fragment>
                            <QueueMusicIcon /> {s.vodcast && <VideocamIcon />}
                        </React.Fragment>
                    </ListItemIcon>
                    {s.id.replace(/_/g, ' ')}
                    <ListItemSecondaryAction>{s.count}</ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

export default SeriesList;
