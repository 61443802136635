import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ReactPlayer from 'react-player';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import { slugify } from '../../../util';

const buildShiurUrl = (shiurID, episodeId) => `${window.location.origin}/shiur/${shiurID}/${episodeId}`


const formatTitle = title => {
    const regex = /(\d{4}-\d{2}-\d{2}) (.*)/;
    const match = title.match(regex);
    if (match) {
        const date = match[1];
        const Title = match[2][0] === '-' ? match[2].substr(2) : match[2];
        return (
            <React.Fragment>
                {Title.split('<br>').map((item, key) => {
                    return <Typography display="inline" variant="h5">{item}<br /></Typography>
                })}
                {' '}
                <Typography
                    color="textSecondary"
                    display="inline"
                    variant="subtitle1"
                >
                    {date}
                </Typography>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {title.split('<br>').map((item, key) => {
                    return <Typography display="inline" variant="h5">{item}<br /></Typography>
                })}
            </React.Fragment>
        );
    }
};

const ShiurCard = ({ shiur, shiurId }) => {
    const [showVideo, setShowVideo] = useState(false);
    return (
        <Box mb={1}>
            <Card>
                <CardHeader
                    subheader={
                        <React.Fragment>
                            {shiur.video && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showVideo}
                                            onChange={() =>
                                                setShowVideo(!showVideo)
                                            }
                                            value="video"
                                        />
                                    }
                                    label="Show Video"
                                />
                            )}

                            <Typography
                                component="span"
                                display="inline"
                                variant="body1"
                            >
                                {' '}
                                <Button
                                    color="secondary"
                                    component="a"
                                    download
                                    href={showVideo ? shiur.video : shiur.audio}
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'Download a Shiur',
                                            action: `Shiur downloaded: ${shiur.Title}`,
                                        });
                                    }}
                                    size="small"
                                    startIcon={<GetAppIcon />}
                                    variant="outlined"
                                >
                                    Download
                                </Button>
                            </Typography>
                        </React.Fragment>
                    }
                    title={
                        <a href={buildShiurUrl(shiurId, slugify(shiur.Title))}>{formatTitle(shiur.Title)}</a>
                    }
                />
                <CardContent>
                    <ReactPlayer
                        controls
                        height={showVideo ? '100%' : '2.5rem'}
                        light
                        onPlay={() => {
                            ReactGA.event({
                                category: 'Play Shiur',
                                action: `Shiur played: ${shiur.Title}`,
                            });
                        }}
                        playbackRate={1}
                        playIcon={
                            <PlayCircleFilledWhiteIcon fontSize="large" />
                        }
                        url={showVideo ? shiur.video : shiur.audio}
                        width="100%"
                    />
                </CardContent>
            </Card>
        </Box>
    );
};

export default ShiurCard;
