import React, { Component } from 'react';
import SeriesHeader from './components/SeriesHeader';
import ShiurCard from './components/ShiurCard';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import PaginationList from './components/PaginationList';

// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

class Shiurim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            episodes: [],
            topic: false,
            shiurId: null,
            orderNewestFirst: false,
        };
    }
    componentDidMount() {
        const {
            match: {
                params: { shiurId },
            },
        } = this.props;
        this.fetchShiurim(shiurId);
        this.setState({shiurId: shiurId})
    }

    reverseOrder = () => {
        this.setState(state => ({
            orderNewestFirst: !state.orderNewestFirst,
            episodes: state.episodes.reverse(),
        }));
    };

    fetchShiurim = shiurId => {
        axios
            .get(`/Rabbi_Viener/${shiurId}/episodes.json`)
            .then(res => this.setState({ episodes: res.data }));
        axios
            .get(`/Rabbi_Viener/${shiurId}/topic.json`)
            .then(res => this.setState({ topic: res.data }));
    };

    render() {
        return (
            <Container maxWidth="md">
                {this.state.episodes.length > 0 && this.state.topic ? (
                    <React.Fragment>
                        <SeriesHeader data={this.state.topic} />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.orderNewestFirst}
                                    color="primary"
                                    onChange={() => this.reverseOrder()}
                                    size="medium"
                                    value="order"
                                />
                            }
                            label="Show Newest First"
                        />
                        <hr />
                        <PaginationList
                            items={this.state.episodes}
                            numPerPage={5}
                            renderFunction={episodes =>
                                episodes.map((ep, index) => (
                                    <ShiurCard key={ep.Title} shiur={ep} shiurId={this.state.shiurId} />
                                ))
                            }
                        />
                    </React.Fragment>
                ) : (
                    <Box mt={20}>
                        <Typography align="center" component="div">
                            <CircularProgress
                                color="primary"
                                size={100}
                                thickness={2}
                            />
                        </Typography>
                    </Box>
                )}
            </Container>
        );
    }
}

export default withRouter(Shiurim);
